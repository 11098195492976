var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Base } from './base';
import { Browser } from './browser';
import { isVisible, matches } from './dom';
import { Property, NotifyPropertyChanges, Event } from './notify-property-change';
import { EventHandler } from './event-handler';
import { compareElementParent } from './util';
/**
 * Droppable Module provides support to enable droppable functionality in Dom Elements.
 * ```html
 * <div id='drop'>Droppable</div>
 * <script>
 * let ele:HTMLElement = document.getElementById('drop');
 * var drag:Droppable = new Droppable(ele,{
 *     accept:'.drop',
 *     drop: function(e) {
 *      //drop handler code.
 *     }
 * });
 * </script>
 * ```
 */
let Droppable = class Droppable extends Base {
    constructor(element, options) {
        super(options, element);
        this.mouseOver = false;
        this.dragData = {};
        this.dragStopCalled = false;
        this.bind();
    }
    bind() {
        this.wireEvents();
    }
    wireEvents() {
        EventHandler.add(this.element, Browser.touchEndEvent, this.intDrop, this);
    }
    // triggers when property changed
    onPropertyChanged(newProp, oldProp) {
        //No Code to handle
    }
    getModuleName() {
        return 'droppable';
    }
    intOver(event, element) {
        if (!this.mouseOver) {
            let drag = this.dragData[this.scope];
            this.trigger('over', { event: event, target: element, dragData: drag });
            this.mouseOver = true;
        }
    }
    intOut(event, element) {
        if (this.mouseOver) {
            this.trigger('out', { evt: event, target: element });
            this.mouseOver = false;
        }
    }
    intDrop(evt, element) {
        if (!this.dragStopCalled) {
            return;
        }
        else {
            this.dragStopCalled = false;
        }
        let accept = true;
        let drag = this.dragData[this.scope];
        let isDrag = drag ? (drag.helper && isVisible(drag.helper)) : false;
        let area;
        if (isDrag) {
            area = this.isDropArea(evt, drag.helper, element);
            if (this.accept) {
                accept = matches(drag.helper, this.accept);
            }
        }
        if (isDrag && this.drop && area.canDrop && accept) {
            this.trigger('drop', { event: evt, target: area.target, droppedElement: drag.helper, dragData: drag });
        }
        this.mouseOver = false;
    }
    isDropArea(evt, helper, element) {
        let area = { canDrop: true, target: element || evt.target };
        let isTouch = evt.type === 'touchend';
        if (isTouch || area.target === helper) {
            helper.style.display = 'none';
            let coord = isTouch ? (evt.changedTouches[0]) : evt;
            let ele = document.elementFromPoint(coord.clientX, coord.clientY);
            area.canDrop = false;
            area.canDrop = compareElementParent(ele, this.element);
            if (area.canDrop) {
                area.target = ele;
            }
            helper.style.display = '';
        }
        return area;
    }
    destroy() {
        EventHandler.remove(this.element, Browser.touchEndEvent, this.intDrop);
        super.destroy();
    }
};
__decorate([
    Property()
], Droppable.prototype, "accept", void 0);
__decorate([
    Property('default')
], Droppable.prototype, "scope", void 0);
__decorate([
    Event()
], Droppable.prototype, "drop", void 0);
__decorate([
    Event()
], Droppable.prototype, "over", void 0);
__decorate([
    Event()
], Droppable.prototype, "out", void 0);
Droppable = __decorate([
    NotifyPropertyChanges
], Droppable);
export { Droppable };
