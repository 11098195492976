import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Schedule } from '@syncfusion/ej2-schedule';
import { Template } from '@syncfusion/ej2-angular-base';
import { ViewsDirective } from './views.directive';
import { ResourcesDirective } from './resources.directive';
import { HeaderRowsDirective } from './headerrows.directive';

export const /** @type {?} */ inputs: string[] = ['agendaDaysCount','allowDragAndDrop','allowInline','allowKeyboardInteraction','allowMultiCellSelection','allowMultiRowSelection','allowResizing','calendarMode','cellHeaderTemplate','cellTemplate','cssClass','currentView','dateFormat','dateHeaderTemplate','editorTemplate','enablePersistence','enableRecurrenceValidation','enableRtl','endHour','eventDragArea','eventSettings','firstDayOfWeek','group','headerRows','height','hideEmptyAgendaDays','locale','maxDate','minDate','quickInfoOnSelectionEnd','quickInfoTemplates','readonly','resourceHeaderTemplate','resources','rowAutoHeight','selectedDate','showHeaderBar','showQuickInfo','showTimeIndicator','showWeekNumber','showWeekend','startHour','timeScale','timezone','views','weekRule','width','workDays','workHours'];
export const /** @type {?} */ outputs: string[] = ['actionBegin','actionComplete','actionFailure','cellClick','cellDoubleClick','created','dataBinding','dataBound','destroyed','drag','dragStart','dragStop','eventClick','eventRendered','hover','moreEventsClick','navigating','popupClose','popupOpen','renderCell','resizeStart','resizeStop','resizing','select','currentViewChange','selectedDateChange'];
export const /** @type {?} */ twoWays: string[] = ['currentView', 'selectedDate'];
/**
 * `ej-schedule` represents the Angular Schedule Component.
 * ```html
 * <ejs-schedule></ejs-schedule>
 * ```
 */
@ComponentMixins([ComponentBase])
export class ScheduleComponent extends Schedule implements IComponentBase {
public context : any;
public tagObjects: any;
public childViews: QueryList<ViewsDirective>;
public childResources: QueryList<ResourcesDirective>;
public childHeaderRows: QueryList<HeaderRowsDirective>;
public tags: string[] = ['views', 'resources', 'headerRows'];
public currentViewChange: any;
public selectedDateChange: any;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 * the date header cells. The field that can be accessed via this template is `date`. 
 * {% codeBlock src='schedule/dateHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 */
@Template()
    public dateHeaderTemplate: any;
/**
 * The template option which is used to render the customized work cells on the Schedule. Here, the template accepts either 
 *  the string or HTMLElement as template design and then the parsed design is displayed onto the work cells. 
 *  The fields accessible via template are as follows. 
 *  * date 
 *  * groupIndex 
 *  * type 
 * {% codeBlock src='schedule/cellTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 */
@Template()
    public cellTemplate: any;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 * the month date cells. This template is only applicable for month view day cells. 
 * {% codeBlock src='schedule/cellHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 */
@Template()
    public cellHeaderTemplate: any;
    
    @Template()
public eventSettings_tooltipTemplate: any;
    
    @Template()
public eventSettings_template: any;
/**
 * The template option to render the customized editor window. The form elements defined within this template should be accompanied 
 *  with `e-field` class, so as to fetch and process it from internally. 
 * {% codeBlock src='schedule/editorTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 */
@Template()
    public editorTemplate: any;
    
    @Template()
public timeScale_minorSlotTemplate: any;
    
    @Template()
public timeScale_majorSlotTemplate: any;
/**
 * Template option to customize the resource header bar. Here, the template accepts either 
 *  the string or HTMLElement as template design and then the parsed design is displayed onto the resource header cells. 
 * The following can be accessible via template. 
 * * resource - All the resource fields. 
 * * resourceData - object collection of current resource. 
 * {% codeBlock src='schedule/resourceHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 */
@Template()
    public resourceHeaderTemplate: any;
    
    @Template()
public quickInfoTemplates_header: any;
    
    @Template()
public quickInfoTemplates_content: any;
    
    @Template()
public quickInfoTemplates_footer: any;
    
    @Template()
public group_headerTooltipTemplate: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];
        try {
                let mod = this.injector.get('ScheduleDay');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleWeek');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleWorkWeek');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleMonth');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleYear');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleAgenda');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleMonthAgenda');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleTimelineViews');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleTimelineMonth');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleTimelineYear');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleResize');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleDragAndDrop');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleExcelExport');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleICalendarExport');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('ScheduleICalendarImport');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('SchedulePrint');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childViews;
        
	    if (this.childResources) {
            this.tagObjects[1].instance = this.childResources;
        }
        
	    if (this.childHeaderRows) {
            this.tagObjects[2].instance = this.childHeaderRows;
        }
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-schedule',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childViews: new ContentChild(ViewsDirective), 
        childResources: new ContentChild(ResourcesDirective), 
        childHeaderRows: new ContentChild(HeaderRowsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'dateHeaderTemplate': [{ type: ContentChild, args: ['dateHeaderTemplate', ] },],
'cellTemplate': [{ type: ContentChild, args: ['cellTemplate', ] },],
'cellHeaderTemplate': [{ type: ContentChild, args: ['cellHeaderTemplate', ] },],
'eventSettings_tooltipTemplate': [{ type: ContentChild, args: ['eventSettingsTooltipTemplate', ] },],
'eventSettings_template': [{ type: ContentChild, args: ['eventSettingsTemplate', ] },],
'editorTemplate': [{ type: ContentChild, args: ['editorTemplate', ] },],
'timeScale_minorSlotTemplate': [{ type: ContentChild, args: ['timeScaleMinorSlotTemplate', ] },],
'timeScale_majorSlotTemplate': [{ type: ContentChild, args: ['timeScaleMajorSlotTemplate', ] },],
'resourceHeaderTemplate': [{ type: ContentChild, args: ['resourceHeaderTemplate', ] },],
'quickInfoTemplates_header': [{ type: ContentChild, args: ['quickInfoTemplatesHeader', ] },],
'quickInfoTemplates_content': [{ type: ContentChild, args: ['quickInfoTemplatesContent', ] },],
'quickInfoTemplates_footer': [{ type: ContentChild, args: ['quickInfoTemplatesFooter', ] },],
'group_headerTooltipTemplate': [{ type: ContentChild, args: ['groupHeaderTooltipTemplate', ] },],
};
}

function ScheduleComponent_tsickle_Closure_declarations() {
/** @type {?} */
ScheduleComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ScheduleComponent.ctorParameters;
/** @type {?} */
ScheduleComponent.propDecorators;
/** @type {?} */
ScheduleComponent.prototype.context;
/** @type {?} */
ScheduleComponent.prototype.tagObjects;
/** @type {?} */
ScheduleComponent.prototype.childViews;
/** @type {?} */
ScheduleComponent.prototype.childResources;
/** @type {?} */
ScheduleComponent.prototype.childHeaderRows;
/** @type {?} */
ScheduleComponent.prototype.tags;
/** @type {?} */
ScheduleComponent.prototype.currentViewChange;
/** @type {?} */
ScheduleComponent.prototype.selectedDateChange;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 * the date header cells. The field that can be accessed via this template is `date`. 
 * {% codeBlock src='schedule/dateHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 * @type {?}
 */
ScheduleComponent.prototype.dateHeaderTemplate;
/**
 * The template option which is used to render the customized work cells on the Schedule. Here, the template accepts either 
 *  the string or HTMLElement as template design and then the parsed design is displayed onto the work cells. 
 *  The fields accessible via template are as follows. 
 *  * date 
 *  * groupIndex 
 *  * type 
 * {% codeBlock src='schedule/cellTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 * @type {?}
 */
ScheduleComponent.prototype.cellTemplate;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 * the month date cells. This template is only applicable for month view day cells. 
 * {% codeBlock src='schedule/cellHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 * @type {?}
 */
ScheduleComponent.prototype.cellHeaderTemplate;
/** @type {?} */
ScheduleComponent.prototype.eventSettings_tooltipTemplate;
/** @type {?} */
ScheduleComponent.prototype.eventSettings_template;
/**
 * The template option to render the customized editor window. The form elements defined within this template should be accompanied 
 *  with `e-field` class, so as to fetch and process it from internally. 
 * {% codeBlock src='schedule/editorTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 * @type {?}
 */
ScheduleComponent.prototype.editorTemplate;
/** @type {?} */
ScheduleComponent.prototype.timeScale_minorSlotTemplate;
/** @type {?} */
ScheduleComponent.prototype.timeScale_majorSlotTemplate;
/**
 * Template option to customize the resource header bar. Here, the template accepts either 
 *  the string or HTMLElement as template design and then the parsed design is displayed onto the resource header cells. 
 * The following can be accessible via template. 
 * * resource - All the resource fields. 
 * * resourceData - object collection of current resource. 
 * {% codeBlock src='schedule/resourceHeaderTemplate/index.md' %}{% endcodeBlock %}
 * \@default null
 * @type {?}
 */
ScheduleComponent.prototype.resourceHeaderTemplate;
/** @type {?} */
ScheduleComponent.prototype.quickInfoTemplates_header;
/** @type {?} */
ScheduleComponent.prototype.quickInfoTemplates_content;
/** @type {?} */
ScheduleComponent.prototype.quickInfoTemplates_footer;
/** @type {?} */
ScheduleComponent.prototype.group_headerTooltipTemplate;
/** @type {?} */
ScheduleComponent.prototype.registerEvents;
/** @type {?} */
ScheduleComponent.prototype.addTwoWay;
/** @type {?} */
ScheduleComponent.prototype.ngEle;
/** @type {?} */
ScheduleComponent.prototype.srenderer;
/** @type {?} */
ScheduleComponent.prototype.viewContainerRef;
/** @type {?} */
ScheduleComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
