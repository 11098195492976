/**
 * Template Engine Bridge
 */
import { compile as render } from './template';
import { createElement } from './dom';
import { isNullOrUndefined, isBlazor } from './util';
const HAS_ROW = /^[\n\r.]+\<tr|^\<tr/;
const HAS_SVG = /^[\n\r.]+\<svg|^\<path|^\<g/;
export let blazorTemplates = {};
export function getRandomId() {
    return '-' + Math.random().toString(36).substr(2, 5);
}
/**
 * Compile the template string into template function.
 * @param  {string} templateString - The template string which is going to convert.
 * @param  {Object} helper? - Helper functions as an object.
 * @private
 */
//tslint:disable-next-line
export function compile(templateString, helper) {
    let compiler = engineObj.compile(templateString, helper);
    //tslint:disable-next-line
    return (data, component, propName, templateId, isStringTemplate, index, element) => {
        let result = compiler(data, component, propName, element);
        let blazor = 'Blazor';
        let blazorTemplateId = 'BlazorTemplateId';
        if (isBlazor() && !isStringTemplate) {
            let randomId = getRandomId();
            let blazorId = templateId + randomId;
            if (!blazorTemplates[templateId]) {
                blazorTemplates[templateId] = [];
            }
            if (!isNullOrUndefined(index)) {
                let keys = Object.keys(blazorTemplates[templateId][index]);
                for (let key of keys) {
                    if (key !== blazorTemplateId && data[key]) {
                        blazorTemplates[templateId][index][key] = data[key];
                    }
                    if (key === blazorTemplateId) {
                        blazorId = blazorTemplates[templateId][index][key];
                    }
                }
            }
            else {
                data[blazorTemplateId] = blazorId;
                blazorTemplates[templateId].push(data);
            }
            // tslint:disable-next-line:no-any
            return propName === 'rowTemplate' ? [createElement('tr', { id: blazorId, className: 'e-blazor-template' })] :
                // tslint:disable-next-line:no-any
                [createElement('div', { id: blazorId, className: 'e-blazor-template' })];
        }
        if (typeof result === 'string') {
            if (HAS_SVG.test(result)) {
                let ele = createElement('svg', { innerHTML: result });
                return ele.childNodes;
            }
            else {
                let ele = createElement((HAS_ROW.test(result) ? 'table' : 'div'), { innerHTML: result });
                return ele.childNodes;
            }
        }
        else {
            return result;
        }
    };
}
export function updateBlazorTemplate(templateId, templateName, comp, isEmpty, callBack) {
    let blazor = 'Blazor';
    if (isBlazor()) {
        let ejsIntrop = 'sfBlazor';
        window[ejsIntrop].updateTemplate(templateName, blazorTemplates[templateId], templateId, comp, callBack);
        if (isEmpty !== false) {
            blazorTemplates[templateId] = [];
        }
    }
}
export function resetBlazorTemplate(templateId, templateName, index) {
    let templateDiv = document.getElementById(templateId);
    if (templateDiv) {
        // tslint:disable-next-line:no-any
        let innerTemplates = templateDiv.getElementsByClassName('blazor-inner-template');
        for (let i = 0; i < innerTemplates.length; i++) {
            let tempId = ' ';
            if (!isNullOrUndefined(index)) {
                tempId = innerTemplates[index].getAttribute('data-templateId');
            }
            else {
                tempId = innerTemplates[i].getAttribute('data-templateId');
            }
            let tempElement = document.getElementById(tempId);
            if (tempElement) {
                let length = tempElement.childNodes.length;
                for (let j = 0; j < length; j++) {
                    if (!isNullOrUndefined(index)) {
                        innerTemplates[index].appendChild(tempElement.childNodes[0]);
                        i = innerTemplates.length;
                    }
                    else {
                        innerTemplates[i].appendChild(tempElement.childNodes[0]);
                    }
                }
            }
        }
    }
}
/**
 * Set your custom template engine for template rendering.
 * @param  {ITemplateEngine} classObj - Class object for custom template.
 * @private
 */
export function setTemplateEngine(classObj) {
    engineObj.compile = classObj.compile;
}
/**
 * Get current template engine for template rendering
 * @param  {ITemplateEngine} classObj - Class object for custom template.
 * @private
 */
export function getTemplateEngine() {
    return engineObj.compile;
}
//Default Engine Class
class Engine {
    compile(templateString, helper = {}) {
        return render(templateString, helper);
    }
}
let engineObj = { compile: new Engine().compile };
