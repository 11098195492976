/**
 * Row class
 * @private
 */
export class Row {
}
/**
 * Rows class
 * @private
 */
export class Rows extends Array {
    constructor() {
        super(...arguments);
        this.add = (row) => {
            let inserted = false;
            let count = 0;
            for (let r of this) {
                if (r.index === row.index) {
                    this[count] = row;
                    inserted = true;
                }
                count++;
            }
            if (!inserted) {
                this.push(row);
            }
        };
    }
}
