import { Directive, ViewContainerRef, ContentChildren } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';



let /** @type {?} */ input: string[] = ['allowMultiple', 'colorField', 'cssClassField', 'dataSource', 'endHourField', 'expandedField', 'field', 'groupIDField', 'idField', 'name', 'query', 'startHourField', 'textField', 'title', 'workDaysField'];
let /** @type {?} */ outputs: string[] = [];
/**
 * `e-resources` directive represent a resources of the Angular Schedule. 
 * It must be contained in a Schedule component(`ejs-schedule`). 
 * ```html
 * <ejs-schedule>
 *   <e-resources>
 *    <e-resource field='RoomId' name='Rooms'></e-resource>
 *    <e-resource field='OwnerId' name='Owners'></e-resource>
 *   </e-resources>
 * </ejs-schedule>
 * ```
 */
export class ResourceDirective extends ComplexBase<ResourceDirective> {
public directivePropList: any;
/**
 * When set to true, allows multiple selection of resource names, thus creating multiple instances of same appointment for the 
 *  selected resources.
 * \@default false
 */
public allowMultiple: any;
/**
 * It maps the `color` field from the dataSource, which is used to specify colors for the resources.
 * \@default 'Color'
 */
public colorField: any;
/**
 * It maps the `cssClass` field from the dataSource, which is used to specify different styles to each resource appointments.
 * \@default 'CssClass'
 */
public cssClassField: any;
/**
 * Assigns the resource dataSource 
 * The data can be passed either as an array of JavaScript objects, 
 * or else can create an instance of [`DataManager`](http://ej2.syncfusion.com/documentation/data/api-dataManager.html) 
 * in case of processing remote data and can be assigned to the `dataSource` property. 
 * With the remote data assigned to dataSource, check the available 
 *  [adaptors](http://ej2.syncfusion.com/documentation/data/adaptors.html) to customize the data processing.
 * \@default []
 * \@isgenerictype true
 */
public dataSource: any;
/**
 * It maps the `endHour` field from the dataSource, which is used to specify different work end hour for each resources.
 * \@default 'EndHour'
 */
public endHourField: any;
/**
 * It maps the `expanded` field from the dataSource, which is used to specify whether each resource levels 
 * in timeline view needs to be maintained in an expanded or collapsed state by default.
 * \@default 'Expanded'
 */
public expandedField: any;
/**
 * A value that binds to the resource field of event object.
 * \@default null
 */
public field: any;
/**
 * It maps the `groupID` field from the dataSource, which is used to specify under which parent resource, 
 *  the child should be grouped.
 * \@default 'GroupID'
 */
public groupIDField: any;
/**
 * It maps the `id` field from the dataSource and is used to uniquely identify the resources.
 * \@default 'Id'
 */
public idField: any;
/**
 * It represents a unique resource name for differentiating various resource objects while grouping.
 * \@default null
 */
public name: any;
/**
 * Defines the external [`query`](http://ej2.syncfusion.com/documentation/data/api-query.html) 
 * that will be executed along with the data processing.
 * \@default null
 */
public query: any;
/**
 * It maps the `startHour` field from the dataSource, which is used to specify different work start hour for each resources.
 * \@default 'StartHour'
 */
public startHourField: any;
/**
 * It maps the `text` field from the dataSource, which is used to specify the resource names.
 * \@default 'Text'
 */
public textField: any;
/**
 * It holds the title of the resource field to be displayed on the schedule event editor window.
 * \@default null
 */
public title: any;
/**
 * It maps the working days field from the dataSource, which is used to specify different working days for each resources.
 * \@default 'WorkDays'
 */
public workDaysField: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-resources>e-resource',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
}

function ResourceDirective_tsickle_Closure_declarations() {
/** @type {?} */
ResourceDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ResourceDirective.ctorParameters;
/** @type {?} */
ResourceDirective.prototype.directivePropList;
/**
 * When set to true, allows multiple selection of resource names, thus creating multiple instances of same appointment for the 
 *  selected resources.
 * \@default false
 * @type {?}
 */
ResourceDirective.prototype.allowMultiple;
/**
 * It maps the `color` field from the dataSource, which is used to specify colors for the resources.
 * \@default 'Color'
 * @type {?}
 */
ResourceDirective.prototype.colorField;
/**
 * It maps the `cssClass` field from the dataSource, which is used to specify different styles to each resource appointments.
 * \@default 'CssClass'
 * @type {?}
 */
ResourceDirective.prototype.cssClassField;
/**
 * Assigns the resource dataSource 
 * The data can be passed either as an array of JavaScript objects, 
 * or else can create an instance of [`DataManager`](http://ej2.syncfusion.com/documentation/data/api-dataManager.html) 
 * in case of processing remote data and can be assigned to the `dataSource` property. 
 * With the remote data assigned to dataSource, check the available 
 *  [adaptors](http://ej2.syncfusion.com/documentation/data/adaptors.html) to customize the data processing.
 * \@default []
 * \@isgenerictype true
 * @type {?}
 */
ResourceDirective.prototype.dataSource;
/**
 * It maps the `endHour` field from the dataSource, which is used to specify different work end hour for each resources.
 * \@default 'EndHour'
 * @type {?}
 */
ResourceDirective.prototype.endHourField;
/**
 * It maps the `expanded` field from the dataSource, which is used to specify whether each resource levels 
 * in timeline view needs to be maintained in an expanded or collapsed state by default.
 * \@default 'Expanded'
 * @type {?}
 */
ResourceDirective.prototype.expandedField;
/**
 * A value that binds to the resource field of event object.
 * \@default null
 * @type {?}
 */
ResourceDirective.prototype.field;
/**
 * It maps the `groupID` field from the dataSource, which is used to specify under which parent resource, 
 *  the child should be grouped.
 * \@default 'GroupID'
 * @type {?}
 */
ResourceDirective.prototype.groupIDField;
/**
 * It maps the `id` field from the dataSource and is used to uniquely identify the resources.
 * \@default 'Id'
 * @type {?}
 */
ResourceDirective.prototype.idField;
/**
 * It represents a unique resource name for differentiating various resource objects while grouping.
 * \@default null
 * @type {?}
 */
ResourceDirective.prototype.name;
/**
 * Defines the external [`query`](http://ej2.syncfusion.com/documentation/data/api-query.html) 
 * that will be executed along with the data processing.
 * \@default null
 * @type {?}
 */
ResourceDirective.prototype.query;
/**
 * It maps the `startHour` field from the dataSource, which is used to specify different work start hour for each resources.
 * \@default 'StartHour'
 * @type {?}
 */
ResourceDirective.prototype.startHourField;
/**
 * It maps the `text` field from the dataSource, which is used to specify the resource names.
 * \@default 'Text'
 * @type {?}
 */
ResourceDirective.prototype.textField;
/**
 * It holds the title of the resource field to be displayed on the schedule event editor window.
 * \@default null
 * @type {?}
 */
ResourceDirective.prototype.title;
/**
 * It maps the working days field from the dataSource, which is used to specify different working days for each resources.
 * \@default 'WorkDays'
 * @type {?}
 */
ResourceDirective.prototype.workDaysField;
/** @type {?} */
ResourceDirective.prototype.viewContainerRef;
}

/**
 * Resource Array Directive
 */
export class ResourcesDirective extends ArrayBase<ResourcesDirective> {
constructor() {
        super('resources');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-schedule>e-resources',
    queries: {
        children: new ContentChildren(ResourceDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ResourcesDirective_tsickle_Closure_declarations() {
/** @type {?} */
ResourcesDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ResourcesDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
