var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Button } from '@syncfusion/ej2-buttons';
import { EventHandler, Property, NotifyPropertyChanges, Animation, attributes } from '@syncfusion/ej2-base';
import { Event, remove, removeClass } from '@syncfusion/ej2-base';
import { Complex, ChildProperty, isBlazor, SanitizeHtmlHelper } from '@syncfusion/ej2-base';
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
const HIDESPINNER = 'e-hide-spinner';
const PROGRESS = 'e-progress';
const PROGRESSACTIVE = 'e-progress-active';
const CONTENTCLS = 'e-btn-content';
/**
 * Defines the spin settings.
 */
export class SpinSettings extends ChildProperty {
}
__decorate([
    Property(null)
], SpinSettings.prototype, "template", void 0);
__decorate([
    Property(16)
], SpinSettings.prototype, "width", void 0);
__decorate([
    Property('Left')
], SpinSettings.prototype, "position", void 0);
/**
 * Defines the animation settings.
 */
export class AnimationSettings extends ChildProperty {
}
__decorate([
    Property(400)
], AnimationSettings.prototype, "duration", void 0);
__decorate([
    Property('None')
], AnimationSettings.prototype, "effect", void 0);
__decorate([
    Property('ease')
], AnimationSettings.prototype, "easing", void 0);
/**
 * The ProgressButton visualizes the progression of an operation to indicate the user
 * that a process is happening in the background with visual representation.
 * ```html
 * <button id="element"></button>
 * ```
 * ```typescript
 * <script>
 * var progressButtonObj = new ProgressButton({ content: 'Progress Button' });
 * progressButtonObj.appendTo("#element");
 * </script>
 * ```
 */
let ProgressButton = class ProgressButton extends Button {
    /**
     * Constructor for creating the widget
     * @param  {ProgressButtonModel} options?
     * @param  {string|HTMLButtonElement} element?
     */
    constructor(options, element) {
        super(options, element);
        this.step = 1;
    }
    preRender() {
        super.preRender();
    }
    /**
     * Initialize the Component rendering
     * @returns void
     * @private
     */
    render() {
        if (isBlazor()) {
            this.isServerRendered = false;
        }
        super.render();
        this.init();
        this.wireEvents();
        this.setAria();
        this.renderComplete();
    }
    /**
     * Starts the button progress at the specified percent.
     * @param percent Starts the button progress at this percent.
     * @returns void
     */
    start(percent) {
        this.isPaused = false;
        this.startProgress(percent ? percent : this.percent, this.progressTime);
    }
    /**
     * Stops the button progress.
     * @returns void
     */
    stop() {
        this.isPaused = true;
        cancelAnimationFrame(this.timerId);
    }
    /**
     * Complete the button progress.
     * @returns void
     */
    progressComplete() {
        this.isPaused = false;
        this.finishProgress();
    }
    /**
     * Get component name.
     * @returns string
     * @private
     */
    getModuleName() {
        return 'progress-btn';
    }
    /**
     * Destroys the widget.
     * @returns void
     */
    destroy() {
        let classList = [HIDESPINNER, PROGRESSACTIVE, 'e-round-corner', 'e-' + super.getModuleName(),
            'e-spin-' + this.spinSettings.position.toLowerCase()];
        let css;
        super.destroy();
        this.unWireEvents();
        this.element.innerHTML = '';
        if (this.cssClass) {
            classList = classList.concat(this.cssClass.split(' '));
        }
        removeClass([this.element], classList);
        css = this.element.getAttribute('class') ? ['aria-label', 'aria-valuemin', 'aria-valuemax', 'aria-valuenow']
            : ['aria-label', 'aria-valuemin', 'aria-valuemax', 'aria-valuenow', 'class'];
        css.forEach((key) => {
            this.element.removeAttribute(key);
        });
        if (this.disabled) {
            this.element.removeAttribute('disabled');
        }
    }
    init() {
        this.element.classList.add('e-' + super.getModuleName());
        this.setContent();
        this.createSpinner();
        if (this.enableProgress) {
            this.createProgress();
        }
    }
    createSpinner() {
        let spinner = this.createElement('span', { className: 'e-spinner' });
        this.setSpinPosition(spinner);
        createSpinner({
            target: spinner, width: this.spinSettings.width || 16, template: this.spinSettings.template
        }, this.createElement);
    }
    getSpinner() {
        return this.element.getElementsByClassName('e-spinner')[0];
    }
    getProgress() {
        return this.element.getElementsByClassName(PROGRESS)[0];
    }
    setSpinPosition(ele) {
        let position = this.spinSettings.position || 'Left';
        if (position === 'Left' || position === 'Top') {
            this.element.insertBefore(ele, this.element.getElementsByClassName(CONTENTCLS)[0]);
        }
        else {
            this.element.appendChild(ele);
        }
        this.element.classList.add('e-spin-' + position.toLowerCase());
    }
    createProgress() {
        this.element.appendChild(this.createElement('span', { className: PROGRESS }));
    }
    setContent() {
        let cont;
        if (isBlazor()) {
            cont = this.content;
            if (this.enableHtmlSanitizer) {
                cont = SanitizeHtmlHelper.sanitize(this.content);
            }
            this.setContentIcon(cont);
        }
        else {
            cont = this.element.innerHTML;
            if (this.enableHtmlSanitizer) {
                cont = SanitizeHtmlHelper.sanitize(this.element.innerHTML);
            }
            this.element.innerHTML = '';
            this.element.appendChild(this.createElement('span', { className: CONTENTCLS, innerHTML: cont }));
        }
    }
    setContentIcon(content) {
        let contElem = this.createElement('span', { className: CONTENTCLS, innerHTML: content });
        if (this.iconCss) {
            let span = this.createElement('span', { className: 'e-btn-icon ' + this.iconCss });
            if (!this.element.textContent.trim()) {
                this.element.classList.add('e-icon-btn');
            }
            else {
                span.classList.add('e-icon-' + this.iconPosition.toLowerCase());
                if (this.iconPosition === 'Top' || this.iconPosition === 'Bottom') {
                    this.element.classList.add('e-' + this.iconPosition.toLowerCase() + '-icon-btn');
                }
            }
            let node = contElem.childNodes[0];
            if (node && (this.iconPosition === 'Left' || this.iconPosition === 'Top')) {
                contElem.insertBefore(span, node);
            }
            else {
                contElem.appendChild(span);
            }
        }
        this.element.appendChild(contElem);
    }
    clickHandler() {
        if (this.element.classList.contains(PROGRESSACTIVE)) {
            return;
        }
        this.startProgress();
    }
    startProgress(percent, progressTime) {
        let clsList = this.element.classList;
        let isVertical = clsList.contains('e-vertical');
        clsList.add(PROGRESSACTIVE);
        if (!(clsList.contains(HIDESPINNER))) {
            showSpinner(this.element);
        }
        this.startAnimate(Date.now(), progressTime ? progressTime : 0, progressTime ? Date.now() - (this.duration * 1 / 100) : Date.now(), percent ? percent : 0, 0, this.step, 0, isVertical);
        this.startContAnimate();
    }
    startAnimate(timestamp, progressTime, prevTime, percent, prevPercent, step, prevProgressTime, isVertical) {
        try {
            let args;
            let timeDiff = timestamp - prevTime;
            let stepTime = this.duration * step / 100;
            let timeDiffBuffer = timeDiff ? (timeDiff < stepTime ? timeDiff - stepTime : timeDiff % stepTime) : 0;
            this.progressTime = progressTime = progressTime + timeDiff - timeDiffBuffer;
            prevTime = timestamp - timeDiffBuffer;
            percent = percent + (timeDiff - timeDiffBuffer) / this.duration * 100;
            prevPercent = ((progressTime - prevProgressTime) % stepTime === 0 || percent === 100) ? percent : prevPercent;
            args = { percent: prevPercent, currentDuration: progressTime, step: step };
            this.eIsVertical = isVertical;
            if (percent === 0) {
                this.trigger('begin', args, (observedArgs) => {
                    this.successCallback(observedArgs, percent, prevPercent, progressTime, prevProgressTime, timeDiffBuffer, prevTime);
                });
            }
            else if (percent === 100 || progressTime === this.duration) {
                this.trigger('end', args, (observedArgs) => {
                    this.successCallback(observedArgs, percent, prevPercent, progressTime, prevProgressTime, timeDiffBuffer, prevTime);
                });
            }
            else {
                this.trigger('progress', args, (observedArgs) => {
                    this.successCallback(observedArgs, percent, prevPercent, progressTime, prevProgressTime, timeDiffBuffer, prevTime);
                });
            }
        }
        catch (e) {
            cancelAnimationFrame(this.timerId);
            this.trigger('fail', e);
        }
    }
    successCallback(args, perc, pPerc, prgTim, pPrgTim, timDif, pTim) {
        let percent = perc;
        let prevPercent = pPerc;
        let timeDiffBuffer = timDif;
        let progressTime = prgTim;
        let prevProgressTime = pPrgTim;
        let prevTime = pTim;
        let isVertical = this.eIsVertical;
        if (percent !== args.percent && args.percent !== prevPercent) {
            percent = args.percent;
        }
        this.percent = percent;
        this.step = args.step;
        if ((progressTime - prevProgressTime) % (this.duration * args.step / 100) === 0 || percent === 100) {
            this.timerId = requestAnimationFrame(() => {
                if (this.enableProgress) {
                    this.getProgress().style[isVertical ? 'height' : 'width'] = percent + '%';
                }
                this.element.setAttribute('aria-valuenow', percent.toString());
            });
            prevPercent = percent;
            prevProgressTime = progressTime;
        }
        if (!this.isPaused) {
            if (progressTime < this.duration && percent < 100) {
                this.interval = window.setTimeout(() => {
                    this.startAnimate(Date.now(), progressTime, prevTime, percent, prevPercent, args.step, prevProgressTime, isVertical);
                    // tslint:disable-next-line
                }, (this.duration / 100) - timeDiffBuffer);
            }
            else {
                this.interval = window.setTimeout(() => {
                    this.progressTime = this.percent = 0;
                    if (this.enableProgress) {
                        this.getProgress().style[isVertical ? 'height' : 'width'] = '0%';
                    }
                    this.element.setAttribute('aria-valuenow', '0');
                    this.hideSpin();
                    // tslint:disable-next-line
                }, 100);
            }
        }
    }
    startContAnimate() {
        let ele = this.element.getElementsByClassName(CONTENTCLS)[0];
        if (this.animationSettings.effect !== 'None') {
            (new Animation({})).animate(ele, {
                duration: this.animationSettings.duration,
                name: 'Progress' + this.animationSettings.effect,
                timingFunction: this.animationSettings.easing,
                begin: () => {
                    if (this.spinSettings.position === 'Center') {
                        this.setSpinnerSize();
                    }
                },
                end: () => {
                    ele.classList.add('e-animate-end');
                }
            });
        }
        else if (this.spinSettings.position === 'Center') {
            this.setSpinnerSize();
        }
    }
    finishProgress() {
        let clsList = this.element.classList;
        let isVertical = clsList.contains('e-vertical');
        clsList.add(PROGRESSACTIVE);
        let args;
        let count = 100;
        for (let i = this.percent; i < count; i++) {
            i += 10;
            if (i > 100) {
                i = 100;
            }
            if (this.enableProgress) {
                this.getProgress().style[isVertical ? 'height' : 'width'] = (this.percent < 100) ? (i + '%') : '100%';
            }
        }
        this.element.setAttribute('aria-valuenow', '0');
        this.hideSpin();
        args = { step: this.step, currentDuration: this.progressTime, percent: 100 };
        clearTimeout(this.interval);
        this.trigger('end', args);
        this.progressTime = this.percent = 0;
    }
    setSpinnerSize() {
        let ele = this.element.getElementsByClassName(CONTENTCLS)[0];
        let spinner = this.getSpinner();
        spinner.style.width = Math.max(spinner.offsetWidth, ele.offsetWidth) + 'px';
        spinner.style.height = Math.max(spinner.offsetHeight, ele.offsetHeight) + 'px';
        ele.classList.add('e-cont-animate');
    }
    hideSpin() {
        let cont = this.element.getElementsByClassName(CONTENTCLS)[0];
        if (!(this.element.classList.contains(HIDESPINNER))) {
            hideSpinner(this.element);
        }
        this.element.classList.remove(PROGRESSACTIVE);
        if (this.animationSettings.effect !== 'None') {
            cont.classList.remove('e-animate-end');
        }
        if (this.spinSettings.position === 'Center') {
            let ele = this.getSpinner();
            cont.classList.remove('e-cont-animate');
            ele.style.width = 'auto';
            ele.style.height = 'auto';
        }
    }
    setIconSpan() {
        let cont = this.element.getElementsByClassName(CONTENTCLS)[0];
        let iconSpan = this.element.getElementsByClassName('e-btn-icon')[0];
        if (cont.childNodes[0] && (this.iconPosition === 'Left' || this.iconPosition === 'Top')) {
            cont.insertBefore(iconSpan, cont.childNodes[0]);
        }
        else {
            cont.appendChild(iconSpan);
        }
    }
    setAria() {
        attributes(this.element, {
            'aria-label': this.element.textContent + ' progress', 'aria-valuemin': '0', 'aria-valuemax': '100', 'aria-valuenow': '0'
        });
    }
    wireEvents() {
        EventHandler.add(this.element, 'click', this.clickHandler, this);
    }
    unWireEvents() {
        EventHandler.remove(this.element, 'click', this.clickHandler);
    }
    /**
     * Called internally if any of the property value changed.
     * @param  {ProgressButtonModel} newProp
     * @param  {ProgressButtonModel} oldProp
     * @returns void
     * @private
     */
    onPropertyChanged(newProp, oldProp) {
        let ele = this.element;
        let isSpinning = false;
        let clsList = this.element.querySelector('.e-spinner-pane').classList;
        if (clsList.contains('e-spin-show')) {
            isSpinning = true;
        }
        super.onPropertyChanged(newProp, oldProp);
        for (let prop of Object.keys(newProp)) {
            switch (prop) {
                case 'content':
                    if (isBlazor()) {
                        let btnElem = this.element.querySelector('.e-btn-content');
                        if (this.iconCss) {
                            if (this.iconPosition === 'Left' || this.iconPosition === 'Top') {
                                btnElem.childNodes[1].textContent = this.content;
                            }
                            else {
                                btnElem.childNodes[0].textContent = this.content;
                            }
                        }
                        else {
                            btnElem.textContent = this.content;
                        }
                    }
                    else {
                        this.setContent();
                        this.createSpinner();
                        if (isSpinning) {
                            showSpinner(this.element);
                            isSpinning = false;
                        }
                        if (this.enableProgress) {
                            this.createProgress();
                        }
                    }
                    ele.setAttribute('aria-label', ele.textContent + ' progress');
                    break;
                case 'iconCss':
                    if (!oldProp.iconCss) {
                        this.setIconSpan();
                    }
                    break;
                case 'iconPosition':
                    this.setIconSpan();
                    break;
                case 'enableProgress':
                    if (newProp.enableProgress) {
                        this.createProgress();
                    }
                    else {
                        remove(this.getProgress());
                    }
                    break;
                case 'spinSettings':
                    if (newProp.spinSettings.position) {
                        ele.classList.remove('e-spin-' + oldProp.spinSettings.position.toLowerCase());
                        this.setSpinPosition(this.getSpinner());
                    }
                    if (newProp.spinSettings.template || newProp.spinSettings.width) {
                        ele.removeChild(this.getSpinner());
                        this.createSpinner();
                    }
                    break;
            }
        }
    }
    /**
     * Sets the focus to ProgressButton
     * its native method
     * @public
     */
    focusIn() {
        this.element.focus();
    }
};
__decorate([
    Property(false)
], ProgressButton.prototype, "enableProgress", void 0);
__decorate([
    Property(2000)
], ProgressButton.prototype, "duration", void 0);
__decorate([
    Property('Left')
], ProgressButton.prototype, "iconPosition", void 0);
__decorate([
    Property('')
], ProgressButton.prototype, "iconCss", void 0);
__decorate([
    Property(false)
], ProgressButton.prototype, "disabled", void 0);
__decorate([
    Property(false)
], ProgressButton.prototype, "isPrimary", void 0);
__decorate([
    Property('')
], ProgressButton.prototype, "cssClass", void 0);
__decorate([
    Property('')
], ProgressButton.prototype, "content", void 0);
__decorate([
    Property(false)
], ProgressButton.prototype, "isToggle", void 0);
__decorate([
    Property(false)
], ProgressButton.prototype, "enableHtmlSanitizer", void 0);
__decorate([
    Complex({}, SpinSettings)
], ProgressButton.prototype, "spinSettings", void 0);
__decorate([
    Complex({}, AnimationSettings)
], ProgressButton.prototype, "animationSettings", void 0);
__decorate([
    Event()
], ProgressButton.prototype, "created", void 0);
__decorate([
    Event()
], ProgressButton.prototype, "begin", void 0);
__decorate([
    Event()
], ProgressButton.prototype, "progress", void 0);
__decorate([
    Event()
], ProgressButton.prototype, "end", void 0);
__decorate([
    Event()
], ProgressButton.prototype, "fail", void 0);
ProgressButton = __decorate([
    NotifyPropertyChanges
], ProgressButton);
export { ProgressButton };
