var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var Sortable_1;
import { Base, Event, getUniqueID, NotifyPropertyChanges, Property } from '@syncfusion/ej2-base';
import { closest, Draggable, remove, compareElementParent } from '@syncfusion/ej2-base';
import { addClass, isNullOrUndefined, getComponent, isBlazor } from '@syncfusion/ej2-base';
/**
 * Sortable Module provides support to enable sortable functionality in Dom Elements.
 * ```html
 * <div id="sortable">
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 *   <div>Item 4</div>
 *   <div>Item 5</div>
 * </div>
 * ```
 * ```typescript
 *   let ele: HTMLElement = document.getElementById('sortable');
 *   let sortObj: Sortable = new Sortable(ele, {});
 * ```
 */
let Sortable = Sortable_1 = class Sortable extends Base {
    constructor(element, options) {
        super(options, element);
        this.getHelper = (e) => {
            let target = this.getSortableElement(e.sender.target);
            if (!this.isValidTarget(target, this)) {
                return false;
            }
            let element;
            if (this.helper) {
                element = this.helper({ sender: target, element: e.element });
            }
            else {
                element = target.cloneNode(true);
                element.style.width = `${target.offsetWidth}px`;
                element.style.height = `${target.offsetHeight}px`;
            }
            addClass([element], ['e-sortableclone']);
            document.body.appendChild(element);
            return element;
        };
        this.onDrag = (e) => {
            this.trigger('drag', { event: e.event, element: this.element, target: e.target });
            let newInst = this.getSortableInstance(e.target);
            let target = this.getSortableElement(e.target, newInst);
            if ((this.isValidTarget(target, newInst) || e.target.className.indexOf('e-list-group-item') > -1) && (this.curTarget !== target ||
                !isNullOrUndefined(newInst.placeHolder)) && (newInst.placeHolderElement ? newInst.placeHolderElement !== e.target :
                true)) {
                if (e.target.className.indexOf('e-list-group-item') > -1) {
                    target = e.target;
                }
                this.curTarget = target;
                if (this.target === target) {
                    return;
                }
                let oldIdx = this.getIndex(newInst.placeHolderElement, newInst);
                let placeHolder = this.getPlaceHolder(target, newInst);
                let newIdx;
                if (placeHolder) {
                    oldIdx = isNullOrUndefined(oldIdx) ? this.getIndex(this.target) : oldIdx;
                    newIdx = this.getIndex(target, newInst, e.event);
                    let isPlaceHolderPresent = this.isPlaceHolderPresent(newInst);
                    if (isPlaceHolderPresent && oldIdx === newIdx) {
                        return;
                    }
                    if (isPlaceHolderPresent) {
                        remove(newInst.placeHolderElement);
                    }
                    newInst.placeHolderElement = placeHolder;
                    if (e.target.className.indexOf('e-list-group-item') > -1) {
                        newInst.element.insertBefore(newInst.placeHolderElement, newInst.element.children[newIdx]);
                    }
                    else if (newInst.element !== this.element && newIdx === newInst.element.childElementCount) {
                        newInst.element.appendChild(newInst.placeHolderElement);
                    }
                    else {
                        newInst.element.insertBefore(newInst.placeHolderElement, newInst.element.children[newIdx]);
                    }
                    this.refreshDisabled(oldIdx, newIdx, newInst);
                }
                else {
                    oldIdx = isNullOrUndefined(oldIdx) ? this.getIndex(this.target) :
                        this.getIndex(target, newInst) < oldIdx || !oldIdx ? oldIdx : oldIdx - 1;
                    newIdx = this.getIndex(target, newInst);
                    let idx = newInst.element !== this.element ? newIdx : oldIdx < newIdx ? newIdx + 1 : newIdx;
                    this.updateItemClass(newInst);
                    newInst.element.insertBefore(this.target, newInst.element.children[idx]);
                    this.refreshDisabled(oldIdx, newIdx, newInst);
                    this.curTarget = this.target;
                    this.trigger('drop', { droppedElement: this.target, element: newInst.element, previousIndex: oldIdx, currentIndex: newIdx,
                        target: e.target, helper: document.getElementsByClassName('e-sortableclone')[0], event: e.event, scope: this.scope });
                }
            }
            newInst = this.getSortableInstance(this.curTarget);
            if (isNullOrUndefined(target) && e.target !== newInst.placeHolderElement) {
                if (this.isPlaceHolderPresent(newInst)) {
                    this.removePlaceHolder(newInst);
                }
            }
            else {
                let placeHolders = [].slice.call(document.getElementsByClassName('e-sortable-placeholder'));
                let inst;
                placeHolders.forEach((placeHolder) => {
                    inst = this.getSortableInstance(placeHolder);
                    if (inst.element && inst !== newInst) {
                        this.removePlaceHolder(inst);
                    }
                });
            }
        };
        this.onDragStart = (e) => {
            this.target = this.getSortableElement(e.target);
            let cancelDrag = false;
            this.target.classList.add('e-grabbed');
            this.curTarget = this.target;
            e.helper = document.getElementsByClassName('e-sortableclone')[0];
            let args = { cancel: false, element: this.element, target: this.target };
            this.trigger('beforeDragStart', args, (observedArgs) => {
                if (observedArgs.cancel) {
                    cancelDrag = observedArgs.cancel;
                    this.onDragStop(e);
                }
            });
            if (cancelDrag) {
                return;
            }
            if (isBlazor) {
                this.trigger('dragStart', { event: e.event, element: this.element, target: this.target,
                    bindEvents: e.bindEvents, dragElement: e.dragElement });
            }
            else {
                this.trigger('dragStart', { event: e.event, element: this.element, target: this.target });
            }
        };
        this.onDragStop = (e) => {
            let dropInst = this.getSortableInstance(this.curTarget);
            let prevIdx;
            let curIdx;
            let handled;
            prevIdx = this.getIndex(this.target);
            let isPlaceHolderPresent = this.isPlaceHolderPresent(dropInst);
            if (isPlaceHolderPresent) {
                let curIdx = this.getIndex(dropInst.placeHolderElement, dropInst);
                let args = { previousIndex: prevIdx, currentIndex: curIdx, target: e.target, droppedElement: this.target,
                    helper: e.helper, cancel: false, handled: false };
                this.trigger('beforeDrop', args, (observedArgs) => {
                    if (!observedArgs.cancel) {
                        handled = observedArgs.handled;
                        this.updateItemClass(dropInst);
                        if (observedArgs.handled) {
                            let ele = this.target.cloneNode(true);
                            this.target.classList.remove('e-grabbed');
                            this.target = ele;
                        }
                        dropInst.element.insertBefore(this.target, dropInst.placeHolderElement);
                        let curIdx = this.getIndex(this.target, dropInst);
                        prevIdx = this === dropInst && (prevIdx - curIdx) > 1 ? prevIdx - 1 : prevIdx;
                        this.trigger('drop', { event: e.event, element: dropInst.element, previousIndex: prevIdx, currentIndex: curIdx,
                            target: e.target, helper: e.helper, droppedElement: this.target, scopeName: this.scope, handled: handled });
                    }
                    remove(dropInst.placeHolderElement);
                });
            }
            dropInst = this.getSortableInstance(e.target);
            curIdx = dropInst.element.childElementCount;
            prevIdx = this.getIndex(this.target);
            if (dropInst.element === e.target || (!isPlaceHolderPresent && this.curTarget === this.target)) {
                let beforeDropArgs = { previousIndex: prevIdx, currentIndex: this.curTarget === this.target ? prevIdx : curIdx,
                    target: e.target, droppedElement: this.target, helper: e.helper, cancel: false };
                this.trigger('beforeDrop', beforeDropArgs, (observedArgs) => {
                    if (dropInst.element === e.target && !observedArgs.cancel) {
                        this.updateItemClass(dropInst);
                        dropInst.element.appendChild(this.target);
                        this.trigger('drop', { event: e.event, element: dropInst.element, previousIndex: prevIdx, currentIndex: curIdx,
                            target: e.target, helper: e.helper, droppedElement: this.target, scopeName: this.scope });
                    }
                });
            }
            this.target.classList.remove('e-grabbed');
            this.target = null;
            this.curTarget = null;
            remove(e.helper);
            getComponent(this.element, Draggable).intDestroy(e.event);
        };
        this.bind();
    }
    bind() {
        if (!this.element.id) {
            this.element.id = getUniqueID('sortable');
        }
        if (!this.itemClass) {
            this.itemClass = 'e-sort-item';
            this.dataBind();
        }
        this.initializeDraggable();
    }
    initializeDraggable() {
        new Draggable(this.element, {
            helper: this.getHelper,
            dragStart: this.onDragStart,
            drag: this.onDrag,
            dragStop: this.onDragStop,
            dragTarget: `.${this.itemClass}`,
            enableTapHold: true,
            tapHoldThreshold: 200,
            queryPositionInfo: this.queryPositionInfo,
            distance: 5
        });
    }
    getPlaceHolder(target, instance) {
        if (instance.placeHolder) {
            let placeHolderElement = instance.placeHolder({ element: instance.element, grabbedElement: this.target, target: target });
            placeHolderElement.classList.add('e-sortable-placeholder');
            return placeHolderElement;
        }
        return null;
    }
    isValidTarget(target, instance) {
        return target && compareElementParent(target, instance.element) && target.classList.contains(instance.itemClass) &&
            !target.classList.contains('e-disabled');
    }
    removePlaceHolder(instance) {
        remove(instance.placeHolderElement);
        instance.placeHolderElement = null;
    }
    updateItemClass(instance) {
        if (this !== instance) {
            this.target.classList.remove(this.itemClass);
            this.target.classList.add(instance.itemClass);
        }
    }
    getSortableInstance(element) {
        element = closest(element, `.e-${this.getModuleName()}`);
        if (element) {
            let inst = getComponent(element, Sortable_1);
            return inst.scope && this.scope && inst.scope === this.scope ? inst : this;
        }
        else {
            return this;
        }
    }
    refreshDisabled(oldIdx, newIdx, instance) {
        if (instance === this) {
            let element;
            let increased = oldIdx < newIdx;
            let disabledIdx;
            let start = increased ? oldIdx : newIdx;
            let end = increased ? newIdx : oldIdx;
            while (start <= end) {
                element = this.element.children[start];
                if (element.classList.contains('e-disabled')) {
                    disabledIdx = this.getIndex(element);
                    this.element.insertBefore(element, this.element.children[increased ? disabledIdx + 2 : disabledIdx - 1]);
                    start = increased ? disabledIdx + 2 : disabledIdx + 1;
                }
                else {
                    start++;
                }
            }
        }
    }
    getIndex(target, instance = this, e) {
        let idx;
        let placeHolderPresent;
        [].slice.call(instance.element.children).forEach((element, index) => {
            if (element.classList.contains('e-sortable-placeholder')) {
                placeHolderPresent = true;
            }
            if (element === target) {
                idx = index;
                if (!isNullOrUndefined(e)) {
                    if (placeHolderPresent) {
                        idx -= 1;
                    }
                    let offset = target.getBoundingClientRect();
                    let clientY = offset.bottom - ((offset.bottom - offset.top) / 2);
                    idx = e.clientY <= clientY ? idx : idx + 1;
                }
                return;
            }
        });
        return idx;
    }
    getSortableElement(element, instance = this) {
        return closest(element, `.${instance.itemClass}`);
    }
    queryPositionInfo(value) {
        value.left = pageXOffset ? `${parseFloat(value.left) - pageXOffset}px` : value.left;
        value.top = pageYOffset ? `${parseFloat(value.top) - pageYOffset}px` : value.top;
        return value;
    }
    isPlaceHolderPresent(instance) {
        return instance.placeHolderElement && !!closest(instance.placeHolderElement, `#${instance.element.id}`);
    }
    /**
     * It is used to sort array of elements from source element to destination element.
     * @param destination - Defines the destination element to which the sortable elements needs to be appended.
     * If it is null, then the Sortable library element will be considered as destination.
     * @param targetIndexes - Specifies the sortable elements indexes which needs to be sorted.
     * @param insertBefore - Specifies the index before which the sortable elements needs to be appended.
     * If it is null, elements will be appended as last child.
     * @method moveTo
     * @return {void}
     */
    moveTo(destination, targetIndexes, insertBefore) {
        moveTo(this.element, destination, targetIndexes, insertBefore);
    }
    /**
     * It is used to destroy the Sortable library.
     */
    destroy() {
        if (this.itemClass === 'e-sort-item') {
            this.itemClass = null;
            this.dataBind();
        }
        getComponent(this.element, Draggable).destroy();
        super.destroy();
    }
    getModuleName() {
        return 'sortable';
    }
    onPropertyChanged(newProp, oldProp) {
        for (let prop of Object.keys(newProp)) {
            switch (prop) {
                case 'itemClass':
                    [].slice.call(this.element.children).forEach((element) => {
                        if (element.classList.contains(oldProp.itemClass)) {
                            element.classList.remove(oldProp.itemClass);
                        }
                        if (newProp.itemClass) {
                            element.classList.add(newProp.itemClass);
                        }
                    });
                    break;
            }
        }
    }
};
__decorate([
    Property(false)
], Sortable.prototype, "enableAnimation", void 0);
__decorate([
    Property(null)
], Sortable.prototype, "itemClass", void 0);
__decorate([
    Property(null)
], Sortable.prototype, "scope", void 0);
__decorate([
    Property()
], Sortable.prototype, "helper", void 0);
__decorate([
    Property()
], Sortable.prototype, "placeHolder", void 0);
__decorate([
    Event()
], Sortable.prototype, "drag", void 0);
__decorate([
    Event()
], Sortable.prototype, "beforeDragStart", void 0);
__decorate([
    Event()
], Sortable.prototype, "dragStart", void 0);
__decorate([
    Event()
], Sortable.prototype, "beforeDrop", void 0);
__decorate([
    Event()
], Sortable.prototype, "drop", void 0);
Sortable = Sortable_1 = __decorate([
    NotifyPropertyChanges
], Sortable);
export { Sortable };
/**
 * It is used to sort array of elements from source element to destination element.
 * @private
 */
export function moveTo(from, to, targetIndexes, insertBefore) {
    let targetElements = [];
    if (!to) {
        to = from;
    }
    if (targetIndexes && targetIndexes.length) {
        targetIndexes.forEach((index) => {
            targetElements.push(from.children[index]);
        });
    }
    else {
        targetElements = [].slice.call(from.children);
    }
    if (isNullOrUndefined(insertBefore)) {
        targetElements.forEach((target) => {
            to.appendChild(target);
        });
    }
    else {
        let insertElement = to.children[insertBefore];
        targetElements.forEach((target) => {
            to.insertBefore(target, insertElement);
        });
    }
}
