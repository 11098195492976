var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/// <reference path='../drop-down-button/drop-down-button-model.d.ts'/>
import { Event, remove, addClass, removeClass, detach, getValue, setValue } from '@syncfusion/ej2-base';
import { EventHandler, Collection, NotifyPropertyChanges, Property } from '@syncfusion/ej2-base';
import { attributes, getUniqueID, getInstance, KeyboardEvents } from '@syncfusion/ej2-base';
import { Button } from '@syncfusion/ej2-buttons';
import { getModel, Item } from './../common/common';
import { DropDownButton } from '../drop-down-button/drop-down-button';
const RTL = 'e-rtl';
const TAGNAME = 'EJS-SPLITBUTTON';
/**
 * SplitButton component has primary and secondary button. Primary button is used to select
 * default action and secondary button is used to toggle contextual overlays for displaying list of
 * action items. It can contain both text and images.
 * ```html
 * <button id="element"></button>
 * ```
 * ```typescript
 * <script>
 * var splitBtnObj = new SplitButton({content: 'SplitButton'});
 * splitBtnObj.appendTo("#element");
 * </script>
 * ```
 */
let SplitButton = class SplitButton extends DropDownButton {
    /**
     * Constructor for creating the widget
     * @param  {SplitButtonModel} options?
     * @param  {string|HTMLButtonElement} element?
     */
    constructor(options, element) {
        super(options, element);
    }
    /**
     * Initialize Angular support.
     * @private
     */
    preRender() {
        let ele = this.element;
        if (ele.tagName === TAGNAME) {
            let ejInstance = getValue('ej2_instances', ele);
            let btn = this.createElement('button', { attrs: { 'type': 'button' } });
            let wrapper = this.createElement(TAGNAME, { className: 'e-' + this.getModuleName() + '-wrapper' });
            for (let idx = 0, len = ele.attributes.length; idx < len; idx++) {
                btn.setAttribute(ele.attributes[idx].nodeName, ele.attributes[idx].nodeValue);
            }
            ele.parentNode.insertBefore(wrapper, ele);
            detach(ele);
            ele = btn;
            wrapper.appendChild(ele);
            setValue('ej2_instances', ejInstance, ele);
            this.wrapper = wrapper;
            this.element = ele;
        }
        if (!this.element.id) {
            this.element.id = getUniqueID('e-' + this.getModuleName());
        }
    }
    /**
     * Initialize the Component rendering
     * @returns void
     * @private
     */
    render() {
        this.initWrapper();
        this.createPrimaryButton();
        this.renderControl();
    }
    renderControl() {
        this.createSecondaryButton();
        this.setActiveElem([this.element, this.secondaryBtnObj.element]);
        this.setAria();
        this.wireEvents();
        this.renderComplete();
    }
    /**
     * Adds a new item to the menu. By default, new item appends to the list as the last item,
     * but you can insert based on the text parameter.
     * @param  { ItemModel[] } items - Specifies an array of JSON data.
     * @param { string } text - Specifies the text to insert the newly added item in the menu.
     * @returns {void}.
     */
    addItems(items, text) {
        super.addItems(items, text);
        this.secondaryBtnObj.items = this.items;
    }
    /**
     * Removes the items from the menu.
     * @param  { string[] } items - Specifies an array of string to remove the items.
     * @param { string } isUniqueId - Set `true` if specified items is a collection of unique id.
     * @returns {void}.
     */
    removeItems(items, isUniqueId) {
        super.removeItems(items, isUniqueId);
        this.secondaryBtnObj.items = this.items;
    }
    initWrapper() {
        if (!this.wrapper) {
            this.wrapper = this.createElement('div', { className: 'e-' + this.getModuleName() + '-wrapper' });
            this.element.parentNode.insertBefore(this.wrapper, this.element);
        }
        this.element.classList.remove('e-' + this.getModuleName());
        if (this.enableRtl) {
            this.wrapper.classList.add(RTL);
        }
        if (this.cssClass) {
            addClass([this.wrapper], this.cssClass.split(' '));
        }
    }
    createPrimaryButton() {
        let btnModel = {
            cssClass: this.cssClass,
            enableRtl: this.enableRtl,
            iconCss: this.iconCss,
            iconPosition: this.iconPosition,
            content: this.content,
            disabled: this.disabled
        };
        this.primaryBtnObj = new Button(btnModel);
        this.primaryBtnObj.createElement = this.createElement;
        this.primaryBtnObj.appendTo(this.element);
        this.element.classList.add('e-' + this.getModuleName());
        this.element.type = 'button';
        this.wrapper.appendChild(this.element);
    }
    createSecondaryButton() {
        let dropDownBtnModel;
        let btnElem;
        btnElem = this.createElement('button', {
            className: 'e-icon-btn',
            attrs: { 'tabindex': '-1' },
            id: this.element.id + '_dropdownbtn'
        });
        this.wrapper.appendChild(btnElem);
        dropDownBtnModel = {
            cssClass: this.cssClass,
            disabled: this.disabled,
            enableRtl: this.enableRtl,
            items: this.items,
            target: this.target,
        };
        dropDownBtnModel.beforeItemRender = (args) => {
            this.trigger('beforeItemRender', args);
        };
        dropDownBtnModel.open = (args) => {
            this.trigger('open', args);
        };
        dropDownBtnModel.close = (args) => {
            this.trigger('close', args);
        };
        dropDownBtnModel.select = (args) => {
            this.trigger('select', args);
        };
        dropDownBtnModel.beforeOpen = (args) => {
            let callBackPromise = new Deferred();
            this.trigger('beforeOpen', args, (observedArgs) => {
                callBackPromise.resolve(observedArgs);
            });
            return callBackPromise;
        };
        dropDownBtnModel.beforeClose = (args) => {
            let callBackPromise = new Deferred();
            this.trigger('beforeClose', args, (observedArgs) => {
                callBackPromise.resolve(observedArgs);
            });
            return callBackPromise;
        };
        this.secondaryBtnObj = new DropDownButton(dropDownBtnModel);
        this.secondaryBtnObj.createElement = this.createElement;
        this.secondaryBtnObj.appendTo(btnElem);
        this.secondaryBtnObj.dropDown.relateTo = this.wrapper;
        this.dropDown = this.secondaryBtnObj.dropDown;
        this.secondaryBtnObj.activeElem = [this.element, this.secondaryBtnObj.element];
        EventHandler.remove(this.getPopUpElement(), 'keydown', this.secondaryBtnObj.keyBoardHandler);
        this.secondaryBtnObj.element.querySelector('.e-btn-icon').classList.remove('e-icon-right');
    }
    setAria() {
        attributes(this.element, {
            'aria-expanded': 'false', 'aria-haspopup': 'true',
            'aria-label': this.element.textContent + ' splitbutton', 'aria-owns': this.secondaryBtnObj.dropDown.element.id
        });
    }
    /**
     * Get component name.
     * @returns string
     * @private
     */
    getModuleName() {
        return 'split-btn';
    }
    /**
     * To open/close SplitButton popup based on current state of the SplitButton.
     * @returns void
     */
    toggle() {
        this.secondaryBtnObj.toggle();
    }
    destroy() {
        let classList = [RTL];
        if (this.cssClass) {
            classList = classList.concat(this.cssClass.split(' '));
        }
        if (this.element) {
            let element = document.getElementById(this.element.id);
            if (element && element.parentElement === this.wrapper) {
                if (this.wrapper.tagName === TAGNAME) {
                    this.wrapper.innerHTML = '';
                    removeClass([this.wrapper], ['e-rtl', 'e-' + this.getModuleName() + '-wrapper']);
                    removeClass([this.wrapper], this.cssClass.split(' '));
                }
                else {
                    removeClass([this.element], classList);
                    ['aria-label', 'aria-haspopup', 'aria-expanded', 'aria-owns', 'type'].forEach((key) => {
                        this.element.removeAttribute(key);
                    });
                    this.wrapper.parentNode.insertBefore(this.element, this.wrapper);
                    remove(this.wrapper);
                }
                this.unWireEvents();
            }
        }
        this.primaryBtnObj.destroy();
        this.secondaryBtnObj.destroy();
        super.destroy();
        if (!this.element.getAttribute('class')) {
            this.element.removeAttribute('class');
        }
    }
    wireEvents() {
        EventHandler.add(this.element, 'click', this.primaryBtnClickHandler, this);
        EventHandler.add(this.getPopUpElement(), 'keydown', this.keyBoardHandler, this);
        new KeyboardEvents(this.element, {
            keyAction: this.btnKeyBoardHandler.bind(this),
            keyConfigs: {
                altdownarrow: 'alt+downarrow'
            }
        });
    }
    unWireEvents() {
        EventHandler.remove(this.element, 'click', this.primaryBtnClickHandler);
        getInstance(this.element, KeyboardEvents).destroy();
    }
    primaryBtnClickHandler() {
        this.trigger('click', { element: this.element });
    }
    btnKeyBoardHandler(e) {
        switch (e.action) {
            case 'altdownarrow':
                this.clickHandler(e);
                break;
        }
    }
    /**
     * Called internally if any of the property value changed.
     * @param  {SplitButtonModel} newProp
     * @param  {SplitButtonModel} oldProp
     * @returns void
     */
    onPropertyChanged(newProp, oldProp) {
        let model = ['content', 'iconCss', 'iconPosition', 'cssClass', 'disabled', 'enableRtl'];
        this.primaryBtnObj.setProperties(getModel(newProp, model));
        model = ['beforeOpen', 'beforeItemRender', 'select', 'open',
            'close', 'cssClass', 'disabled', 'enableRtl'];
        if (Object.keys(newProp).indexOf('items') > -1) {
            this.secondaryBtnObj.items = newProp.items;
            this.secondaryBtnObj.dataBind();
        }
        this.secondaryBtnObj.setProperties(getModel(newProp, model));
        for (let prop of Object.keys(newProp)) {
            switch (prop) {
                case 'cssClass':
                    if (oldProp.cssClass) {
                        removeClass([this.wrapper], oldProp.cssClass.split(' '));
                    }
                    addClass([this.wrapper], newProp.cssClass.split(' '));
                    break;
                case 'enableRtl':
                    if (newProp.enableRtl) {
                        addClass([this.wrapper], RTL);
                    }
                    else {
                        removeClass([this.wrapper], RTL);
                    }
                    break;
            }
        }
    }
    /**
     * Sets the focus to SplitButton
     * its native method
     * @public
     */
    focusIn() {
        this.element.focus();
    }
};
__decorate([
    Property('')
], SplitButton.prototype, "content", void 0);
__decorate([
    Property('')
], SplitButton.prototype, "cssClass", void 0);
__decorate([
    Property(false)
], SplitButton.prototype, "disabled", void 0);
__decorate([
    Property('')
], SplitButton.prototype, "iconCss", void 0);
__decorate([
    Property('Left')
], SplitButton.prototype, "iconPosition", void 0);
__decorate([
    Collection([], Item)
], SplitButton.prototype, "items", void 0);
__decorate([
    Property('')
], SplitButton.prototype, "target", void 0);
__decorate([
    Event()
], SplitButton.prototype, "beforeItemRender", void 0);
__decorate([
    Event()
], SplitButton.prototype, "beforeOpen", void 0);
__decorate([
    Event()
], SplitButton.prototype, "beforeClose", void 0);
__decorate([
    Event()
], SplitButton.prototype, "click", void 0);
__decorate([
    Event()
], SplitButton.prototype, "close", void 0);
__decorate([
    Event()
], SplitButton.prototype, "open", void 0);
__decorate([
    Event()
], SplitButton.prototype, "select", void 0);
__decorate([
    Event()
], SplitButton.prototype, "created", void 0);
SplitButton = __decorate([
    NotifyPropertyChanges
], SplitButton);
export { SplitButton };
/**
 * Deferred is used to handle asynchronous operation.
 */
export class Deferred {
    constructor() {
        /**
         * Promise is an object that represents a value that may not be available yet, but will be resolved at some point in the future.
         */
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        /**
         * Defines the callback function triggers when the Deferred object is rejected.
         */
        this.catch = this.promise.catch.bind(this.promise);
        /**
         * Defines the callback function triggers when the Deferred object is resolved.
         */
        this.then = this.promise.then.bind(this.promise);
    }
}
