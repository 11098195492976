var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ChildProperty, extend, deleteObject, Property, addClass } from '@syncfusion/ej2-base';
/**
 * @param props
 * @param model
 */
export function getModel(props, model) {
    let obj = extend({}, props);
    for (let prop of Object.keys(obj)) {
        if ((model).indexOf(prop) < 0) {
            deleteObject(obj, prop);
        }
    }
    return obj;
}
/** @hidden */
export function upDownKeyHandler(ul, keyCode) {
    let defaultIdx = keyCode === 40 ? 0 : ul.childElementCount - 1;
    let liIdx = defaultIdx;
    let li;
    let selectedLi = ul.querySelector('.e-selected');
    if (selectedLi) {
        selectedLi.classList.remove('e-selected');
    }
    for (let i = 0, len = ul.children.length; i < len; i++) {
        if (ul.children[i].classList.contains('e-focused')) {
            li = ul.children[i];
            liIdx = i;
            li.classList.remove('e-focused');
            keyCode === 40 ? liIdx++ : liIdx--;
            if (liIdx === (keyCode === 40 ? ul.childElementCount : -1)) {
                liIdx = defaultIdx;
            }
        }
    }
    li = ul.children[liIdx];
    liIdx = isValidLI(ul, li, liIdx, keyCode);
    if (liIdx !== -1) {
        addClass([ul.children[liIdx]], 'e-focused');
        ul.children[liIdx].focus();
    }
}
function isValidLI(ul, li, index, keyCode, count = 0) {
    if (li.classList.contains('e-separator') || li.classList.contains('e-disabled')) {
        if (index === (keyCode === 40 ? ul.childElementCount - 1 : 0)) {
            index = keyCode === 40 ? 0 : ul.childElementCount - 1;
        }
        else {
            keyCode === 40 ? index++ : index--;
        }
    }
    li = ul.children[index];
    if (li.classList.contains('e-separator') || li.classList.contains('e-disabled')) {
        count++;
        if (count === ul.childElementCount) {
            return index = -1;
        }
        index = isValidLI(ul, li, index, keyCode, count);
    }
    return index;
}
/** @hidden */
export function setBlankIconStyle(popup) {
    let blankIconList = [].slice.call(popup.getElementsByClassName('e-blank-icon'));
    if (!blankIconList.length) {
        return;
    }
    let iconLi = popup.querySelector('.e-item:not(.e-blank-icon):not(.e-separator)');
    if (iconLi.classList.contains('e-url')) {
        iconLi = iconLi.querySelector('.e-menu-url');
    }
    let icon = iconLi.querySelector('.e-menu-icon');
    let cssProp;
    let enableRtl = popup.classList.contains('e-rtl');
    if (enableRtl) {
        cssProp = { padding: 'paddingRight', margin: 'marginLeft' };
    }
    else {
        cssProp = { padding: 'paddingLeft', margin: 'marginRight' };
    }
    // tslint:disable
    let size = `${parseInt(getComputedStyle(icon).fontSize, 10) + parseInt((enableRtl ? getComputedStyle(icon)[cssProp.margin] : getComputedStyle(icon)[cssProp.margin]), 10)
        + parseInt(getComputedStyle(iconLi).paddingLeft, 10)}px`;
    blankIconList.forEach((li) => {
        if (li.classList.contains('e-url')) {
            li.querySelector('.e-menu-url').style[cssProp.padding] = size;
        }
        else {
            li.style[cssProp.padding] = size;
        }
    });
    // tslint:enable
}
/**
 * Defines the items of Split Button/DropDownButton.
 */
export class Item extends ChildProperty {
}
__decorate([
    Property('')
], Item.prototype, "iconCss", void 0);
__decorate([
    Property('')
], Item.prototype, "id", void 0);
__decorate([
    Property(false)
], Item.prototype, "separator", void 0);
__decorate([
    Property('')
], Item.prototype, "text", void 0);
__decorate([
    Property('')
], Item.prototype, "url", void 0);
__decorate([
    Property(false)
], Item.prototype, "disabled", void 0);
