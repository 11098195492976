import { Directive, ViewContainerRef, ContentChildren, ContentChild } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';
import { Template } from '@syncfusion/ej2-angular-base';


let /** @type {?} */ input: string[] = ['allowVirtualScrolling', 'cellHeaderTemplate', 'cellTemplate', 'dateFormat', 'dateHeaderTemplate', 'displayName', 'endHour', 'eventTemplate', 'firstDayOfWeek', 'group', 'headerRows', 'interval', 'isSelected', 'option', 'orientation', 'readonly', 'resourceHeaderTemplate', 'showWeekNumber', 'showWeekend', 'startHour', 'timeScale', 'workDays'];
let /** @type {?} */ outputs: string[] = [];
/**
 * `e-views` directive represent a view of the Angular Schedule. 
 * It must be contained in a Schedule component(`ejs-schedule`). 
 * ```html
 * <ejs-schedule>
 *   <e-views>
 *    <e-view option='day' dateFormat='dd MMM'></e-view>
 *    <e-view option='week'></e-view>
 *   </e-views>
 * </ejs-schedule>
 * ```
 */
export class ViewDirective extends ComplexBase<ViewDirective> {
public directivePropList: any;
/**
 * It is used to allow or disallow the virtual scrolling functionality on Agenda View. This is applicable only on Agenda view.
 * \@default false
 */
public allowVirtualScrolling: any;
/**
 * By default, Schedule follows the date-format as per the default culture assigned to it. It is also possible to manually set 
 *  specific date format by using the `dateFormat` property. The format of the date range label in the header bar depends on 
 *  the `dateFormat` value or else based on the locale assigned to the Schedule. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 */
public dateFormat: any;
/**
 * When the same view is customized with different intervals, this property allows the user to set different display name 
 *  for those views.
 * \@default null
 */
public displayName: any;
/**
 * It is used to specify the end hour, at which the Schedule ends. It too accepts the time string in a short skeleton format.
 * \@default '24:00'
 */
public endHour: any;
/**
 * This option allows the user to set the first day of a week on Schedule. It should be based on the locale set to it and each culture 
 *  defines its own first day of week values. If needed, the user can set it manually on his own by defining the value through 
 *  this property. It usually accepts the integer values, whereby 0 is always denoted as Sunday, 1 as Monday and so on.
 * \@default 0
 */
public firstDayOfWeek: any;
/**
 * Allows to set different resource grouping options on all available schedule view modes.
 * \@default { byDate: false, byGroupID: true, allowGroupEdit: false, resources:[]}
 */
public group: any;
/**
 * Allows defining the collection of custom header rows to display the year, month, week, date and hour label as an individual row 
 *  on the timeline view of the scheduler.
 * \@default []
 */
public headerRows: any;
/**
 * It accepts the number value denoting to include the number of days, weeks, workweeks or months on the defined view type.
 * \@default 1
 */
public interval: any;
/**
 * To denote whether the view name given on the `option` is active or not. 
 * It acts similar to the `currentView` property and defines the active view of Schedule.
 * \@default false
 */
public isSelected: any;
/**
 * It accepts the schedule view name, based on which we can define with its related properties in a single object. 
 * The applicable view names are, 
 * * Day 
 * * Week 
 * * WorkWeek 
 * * Month 
 * * Year 
 * * Agenda 
 * * MonthAgenda 
 * * TimelineDay 
 * * TimelineWeek 
 * * TimelineWorkWeek 
 * * TimelineMonth 
 * * TimelineYear
 * \@default null
 */
public option: any;
/**
 * It is used to specify the year view rendering orientation on the schedule. 
 * The applicable orientation values are, 
 * * Horizontal 
 * * Vertical
 * \@default 'Horizontal'
 */
public orientation: any;
/**
 * When set to `true`, displays a quick popup with cell or event details on single clicking over the cells or on events. 
 *  By default, it is set to `true`. It gets applied only to the view objects on which it is defined.
 * \@default false
 */
public readonly: any;
/**
 * When set to `true`, displays the week number of the current view date range.
 * \@default false
 */
public showWeekNumber: any;
/**
 * When set to `false`, it hides the weekend days of a week from the Schedule. 
 * The days which are not defined in the working days collection are usually treated as weekend days. 
 * Note: By default, this option is not applicable on `Work Week` view. 
 * For example, if the working days are defined as [1, 2, 3, 4], then the remaining days of that week will be considered as the 
 *  weekend days and will be hidden on all the views.
 * \@default true
 */
public showWeekend: any;
/**
 * It is used to specify the starting hour, from which the Schedule starts to display. 
 *  It accepts the time string in a short skeleton format and also, hides the time beyond the specified start time.
 * \@default '00:00'
 */
public startHour: any;
/**
 * Allows to set different timescale configuration on each applicable view modes such as day, week and work week.
 * \@default { enable: true, interval: 60, slotCount: 2, majorSlotTemplate: null, minorSlotTemplate: null }
 */
public timeScale: any;
/**
 * It is used to set the working days on schedule. The only days that are defined in this collection will be rendered on the 
 *  `workWeek` view whereas on other views, it will display all the usual days and simply highlights the working days with different 
 *  shade.
 * \@default '[1, 2, 3, 4, 5]'
 * \@asptype int[]
 */
public workDays: any;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto the 
 *  date header cells. The field that can be accessed via this template is `date`. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 */
@Template()
    public dateHeaderTemplate: any;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto the 
 *  month date cells. 
 *  This template is only applicable for month view day cells.
 * \@default null
 */
@Template()
    public cellHeaderTemplate: any;
/**
 * The template option which is used to render the customized work cells on the Schedule. Here, the 
 *  template accepts either the string or HTMLElement as template design and then the parsed design is displayed onto the work cells. 
 *  The field accessible via template is `date`. It gets applied only to the view objects on which it is defined.
 * \@default null
 */
@Template()
    public cellTemplate: any;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 *  the event background. All the event fields mapped to Schedule from dataSource can be accessed within this template code. 
 *  It is similar to that of the `template` option available within the `eventSettings` property, 
 *  whereas it will get applied only on the events of the view to which it is currently being defined.
 * \@default null
 */
@Template()
    public eventTemplate: any;
/**
 * The template option which is used to render the customized header cells on the schedule. Here, the 
 *  template accepts either the string or HTMLElement as template design and then the parsed design is displayed onto the header cells. 
 *  All the resource fields mapped within resources can be accessed within this template code. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 */
@Template()
    public resourceHeaderTemplate: any;
    
    @Template()
public timeScale_minorSlotTemplate: any;
    
    @Template()
public timeScale_majorSlotTemplate: any;
    
    @Template()
public group_headerTooltipTemplate: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-views>e-view',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'dateHeaderTemplate': [{ type: ContentChild, args: ['dateHeaderTemplate', ] },],
'cellHeaderTemplate': [{ type: ContentChild, args: ['cellHeaderTemplate', ] },],
'cellTemplate': [{ type: ContentChild, args: ['cellTemplate', ] },],
'eventTemplate': [{ type: ContentChild, args: ['eventTemplate', ] },],
'resourceHeaderTemplate': [{ type: ContentChild, args: ['resourceHeaderTemplate', ] },],
'timeScale_minorSlotTemplate': [{ type: ContentChild, args: ['timeScaleMinorSlotTemplate', ] },],
'timeScale_majorSlotTemplate': [{ type: ContentChild, args: ['timeScaleMajorSlotTemplate', ] },],
'group_headerTooltipTemplate': [{ type: ContentChild, args: ['groupHeaderTooltipTemplate', ] },],
};
}

function ViewDirective_tsickle_Closure_declarations() {
/** @type {?} */
ViewDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ViewDirective.ctorParameters;
/** @type {?} */
ViewDirective.propDecorators;
/** @type {?} */
ViewDirective.prototype.directivePropList;
/**
 * It is used to allow or disallow the virtual scrolling functionality on Agenda View. This is applicable only on Agenda view.
 * \@default false
 * @type {?}
 */
ViewDirective.prototype.allowVirtualScrolling;
/**
 * By default, Schedule follows the date-format as per the default culture assigned to it. It is also possible to manually set 
 *  specific date format by using the `dateFormat` property. The format of the date range label in the header bar depends on 
 *  the `dateFormat` value or else based on the locale assigned to the Schedule. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.dateFormat;
/**
 * When the same view is customized with different intervals, this property allows the user to set different display name 
 *  for those views.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.displayName;
/**
 * It is used to specify the end hour, at which the Schedule ends. It too accepts the time string in a short skeleton format.
 * \@default '24:00'
 * @type {?}
 */
ViewDirective.prototype.endHour;
/**
 * This option allows the user to set the first day of a week on Schedule. It should be based on the locale set to it and each culture 
 *  defines its own first day of week values. If needed, the user can set it manually on his own by defining the value through 
 *  this property. It usually accepts the integer values, whereby 0 is always denoted as Sunday, 1 as Monday and so on.
 * \@default 0
 * @type {?}
 */
ViewDirective.prototype.firstDayOfWeek;
/**
 * Allows to set different resource grouping options on all available schedule view modes.
 * \@default { byDate: false, byGroupID: true, allowGroupEdit: false, resources:[]}
 * @type {?}
 */
ViewDirective.prototype.group;
/**
 * Allows defining the collection of custom header rows to display the year, month, week, date and hour label as an individual row 
 *  on the timeline view of the scheduler.
 * \@default []
 * @type {?}
 */
ViewDirective.prototype.headerRows;
/**
 * It accepts the number value denoting to include the number of days, weeks, workweeks or months on the defined view type.
 * \@default 1
 * @type {?}
 */
ViewDirective.prototype.interval;
/**
 * To denote whether the view name given on the `option` is active or not. 
 * It acts similar to the `currentView` property and defines the active view of Schedule.
 * \@default false
 * @type {?}
 */
ViewDirective.prototype.isSelected;
/**
 * It accepts the schedule view name, based on which we can define with its related properties in a single object. 
 * The applicable view names are, 
 * * Day 
 * * Week 
 * * WorkWeek 
 * * Month 
 * * Year 
 * * Agenda 
 * * MonthAgenda 
 * * TimelineDay 
 * * TimelineWeek 
 * * TimelineWorkWeek 
 * * TimelineMonth 
 * * TimelineYear
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.option;
/**
 * It is used to specify the year view rendering orientation on the schedule. 
 * The applicable orientation values are, 
 * * Horizontal 
 * * Vertical
 * \@default 'Horizontal'
 * @type {?}
 */
ViewDirective.prototype.orientation;
/**
 * When set to `true`, displays a quick popup with cell or event details on single clicking over the cells or on events. 
 *  By default, it is set to `true`. It gets applied only to the view objects on which it is defined.
 * \@default false
 * @type {?}
 */
ViewDirective.prototype.readonly;
/**
 * When set to `true`, displays the week number of the current view date range.
 * \@default false
 * @type {?}
 */
ViewDirective.prototype.showWeekNumber;
/**
 * When set to `false`, it hides the weekend days of a week from the Schedule. 
 * The days which are not defined in the working days collection are usually treated as weekend days. 
 * Note: By default, this option is not applicable on `Work Week` view. 
 * For example, if the working days are defined as [1, 2, 3, 4], then the remaining days of that week will be considered as the 
 *  weekend days and will be hidden on all the views.
 * \@default true
 * @type {?}
 */
ViewDirective.prototype.showWeekend;
/**
 * It is used to specify the starting hour, from which the Schedule starts to display. 
 *  It accepts the time string in a short skeleton format and also, hides the time beyond the specified start time.
 * \@default '00:00'
 * @type {?}
 */
ViewDirective.prototype.startHour;
/**
 * Allows to set different timescale configuration on each applicable view modes such as day, week and work week.
 * \@default { enable: true, interval: 60, slotCount: 2, majorSlotTemplate: null, minorSlotTemplate: null }
 * @type {?}
 */
ViewDirective.prototype.timeScale;
/**
 * It is used to set the working days on schedule. The only days that are defined in this collection will be rendered on the 
 *  `workWeek` view whereas on other views, it will display all the usual days and simply highlights the working days with different 
 *  shade.
 * \@default '[1, 2, 3, 4, 5]'
 * \@asptype int[]
 * @type {?}
 */
ViewDirective.prototype.workDays;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto the 
 *  date header cells. The field that can be accessed via this template is `date`. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.dateHeaderTemplate;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto the 
 *  month date cells. 
 *  This template is only applicable for month view day cells.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.cellHeaderTemplate;
/**
 * The template option which is used to render the customized work cells on the Schedule. Here, the 
 *  template accepts either the string or HTMLElement as template design and then the parsed design is displayed onto the work cells. 
 *  The field accessible via template is `date`. It gets applied only to the view objects on which it is defined.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.cellTemplate;
/**
 * It accepts either the string or HTMLElement as template design content and parse it appropriately before displaying it onto 
 *  the event background. All the event fields mapped to Schedule from dataSource can be accessed within this template code. 
 *  It is similar to that of the `template` option available within the `eventSettings` property, 
 *  whereas it will get applied only on the events of the view to which it is currently being defined.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.eventTemplate;
/**
 * The template option which is used to render the customized header cells on the schedule. Here, the 
 *  template accepts either the string or HTMLElement as template design and then the parsed design is displayed onto the header cells. 
 *  All the resource fields mapped within resources can be accessed within this template code. 
 *  It gets applied only to the view objects on which it is defined.
 * \@default null
 * @type {?}
 */
ViewDirective.prototype.resourceHeaderTemplate;
/** @type {?} */
ViewDirective.prototype.timeScale_minorSlotTemplate;
/** @type {?} */
ViewDirective.prototype.timeScale_majorSlotTemplate;
/** @type {?} */
ViewDirective.prototype.group_headerTooltipTemplate;
/** @type {?} */
ViewDirective.prototype.viewContainerRef;
}

/**
 * View Array Directive
 */
export class ViewsDirective extends ArrayBase<ViewsDirective> {
constructor() {
        super('views');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-schedule>e-views',
    queries: {
        children: new ContentChildren(ViewDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ViewsDirective_tsickle_Closure_declarations() {
/** @type {?} */
ViewsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ViewsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
